/* eslint-disable vue/max-attributes-per-line */
<template>
    <div class="hotList">
        <div class="headTitle">
            <van-nav-bar  :title="titleName" :border='false'>
                <template #left>
                <van-icon :size="24" @click="$router.push('/')" color="#141212" name="arrow-left" slot="left" />
                </template>
            </van-nav-bar>
        </div>
        <div class="ht_list">
            <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
                <van-list v-if="goods && goods.length > 0" v-model="loading" :immediate-check="false" :finished="finished" finished-text="没有更多了" @load="onLoad">
                    <div v-for="(item,index) in goods" :key="index" class="ht_list_item" @click="toDetail()">
                        <van-image :src="item.cover" class="ht_img">
                            <template #error>
                                加载失败
                            </template>
                        </van-image>
                        <div class="ht_info">
                            <p>{{ item.name }}</p>
                            <p><van-tag plain type="danger">惠</van-tag></p>
                            <p>
                                <span>￥<em>{{ item.sellPrice ? item.sellPrice/100 : 0 }}</em></span>
                                <span>起</span>
                            </p>
                        </div>
                    </div>
                </van-list>
                <div v-else class="no_data_img" />
            </van-pull-refresh>
        </div>
    </div>
</template>
<script src="./hotList.js" />
<style lang="scss" scoped>
.headTitle {
  width: 100%;
  position: fixed;
  top: 0;
  background-color: white;
  z-index: 999;
}
.hotList {
  width: 100%;
  padding-top: 10px;
  overflow-x: hidden;
  height: 100vh;
}
.ht_list {
    padding: 0 20px 0 20px;
    box-sizing: border-box;
    margin-top: 20px;
}
.ht_list_item {
    width: 48%;
    display: inline-block;
    padding: 10px 0 15px 0;
    vertical-align:top;
    &:nth-of-type(even){
        margin-left: 10px;
    }
    .ht_img {
        width: 100%;
        height: 100px;
        overflow: hidden;
        border-radius: 6px;
    }
    .ht_info {
        width: 100%;
        padding: 10px 0 0 0;
        p {
            font-weight: bold;
            font-size: 14px;
            margin-bottom: 7px;
            span{
                font-size: 12px;
                em{
                    font-size: 16px;
                    font-style: normal;
                    color: red;
                    margin-right: 5px;
                }
            }
            }
    }
}
.no_data_img {
    width: 100%;
    height: 300px;
    background: url('../tickets/img/no_data.png') no-repeat center center;
    background-size: 60%;
}
</style>
