import { NavBar, Tag , Icon, Search, DropdownMenu, DropdownItem, List, PullRefresh, Image } from 'vant'
export default {
  name: 'hotList',
  components:{
    // loginPop,
    'van-pull-refresh': PullRefresh,
    'van-list': List,
    'van-image': Image,
    'van-nav-bar': NavBar,
    'van-icon': Icon,
    'van-search': Search,
    'van-dropdown-menu': DropdownMenu,
    'van-dropdown-item': DropdownItem,
    'van-tag': Tag,
  },
  data () {
    return {
        titleName:'活动优惠',
        token: '',//判断是否登录
        isShowLoginPop:false,
        goods: [], //推荐商品推荐列表
        refreshing: false, //是否刷新
        loading: true, //是否加载中
        finished: false, //数据是否已经加载完成
        params: {   //获取推荐商品
            joinActivity: '1', //固定值1，1:加入活动
            pageIndex: 1, //当前页码
            pageSize: 10, //每页显示记录数
          },
    }
  },
  methods: {
    loadHots() { //获取推荐商品推荐列表
        const me = this;
        me.loading = true;
        me.$get({
          url: '/commodity/queryCommodity',
          params: me.params
        }).then(rsp => {
            me.loading = false;
            if (me.refreshing) {
              me.goods = [];
              me.refreshing = false;
            }
            var list = rsp.data;
            if (list && list.length > 0) {
              me.goods = me.goods.concat(list);
            }
            if (!list || list.length < me.params.pageSize) {
              me.finished = true;
            }
        });
      },
      onRefresh() { //下拉刷新
        const me = this;
        me.finished = false;
        me.params.pageIndex =  1;
        me.loadHots();
      },
      onLoad() { //上拉加载
        const me = this;
        let page = me.params.pageIndex;
        page = page ? page + 1 : 1;
        me.params.pageIndex =  page;
        me.loadHots();
      },
      //跳转到详情界面
        toDetail(row) {
          if(row.goodType == 1 || row.goodType == 2){  //  演艺赛事 景区
            if(priceCalendarCount>0){
              // 根据token判断是否跳转登录
              if(this.$global.token){
                this.$router.push({
                  path:'/tiketDetails/' + row.sellerId,
                  query:{
                    priceCalendarCount:row.priceCalendarCount
                  }
                });
              }else{
                Toast('请先登录！')
              }
            }else{
              this.$router.push('/deduceDetails/' + row.sellerId)
            }
        }else if(row.goodType == 3){  // 酒店
            this.$router.push({
              path: '/hotelInner',
              query: {
                  sellerId: row.sellerId,
              },
          });
          }else if(row.goodType == 4){  //商城
            let url =
              this.$global.baseMallUrl +
              this.$global.token +
              '&redirectUrl=' +
              encodeURIComponent(row.outurl);
          window.location.href = url;
        }else if(row.goodType == 5){  // 组合套餐跳转
              this.$router.push({
                path: '/Combo',
                query: {
                    commodityId: row.commodityId,
                    sellerId: row.sellerId,
                },
            });
          }
        },
  },
  created () {
    const me = this;
    me.loadHots();
  },
  mounted () {
  },
}